<template>
  <div>
    <section-banner
      v-for="(section, index) in sections"
      :key="index"
      :model="section"
    ></section-banner>
  </div>
</template>

<script>
import SectionBanner from "@/components/Frontend/SectionBanner";

export default {
  name: "NotarialServices",
  components: {
    SectionBanner,
  },
  data() {
    return {
      sections: [
        {
          Name: "What we do",
          Title: "Notarial Services",
          Subtitle: "Vetting your documents for use, worldwide",
          Description:
            "From ante-nuptial contracts to powers of attorney, we help you validate documents for use worldwide, to make sure they’re authenticated for any foreign bureaucratic maze you need to negotiate.",
          img: require("@/assets/img/Helanie.jpg"),
          justifyContentRight: true,
        },
        {
          TextColumn: true,
          TextColumn1: `<p>These include:<br></p>
        <ul>
          <li>Document Authentication</li>
          <li>Drafting of Notarial Bonds</li>
          <li>Drafting and advising on Leases</li>
          <li>Notarial Ties</li>
          <li>Power of Attorney</li>
        </ul>`,
          TextColumn2: false,
        },
        {
          Profiles: true,
          Name: "Talk to Us",
          Bios: [
            {
              Title: "Helani van der Westhuizen",
              Subtitle: "Director",
              Linkedin: "linkedin.com/in/helani-van-der-westhuizen",
              Email: "helani@NLAteam.com",
              Tel: "+27 11 704 1563",
              img: require("@/assets/img/Helanie-bio.jpg"),
              left: true,
              last: true,
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
